<script lang="ts" setup>
import type { BaseImageFragment } from "~~/types/graphql";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
const { t } = useI18n();

enum FALLBACK_COLOR {
  Black = "black",
  Gray = "gray",
  Green = "green",
  White = "white",
}

// map all possible types to local types
export type SliderItems = Array<SliderItem | null>;
export type SliderPage = { data: { attributes: { Slug: string } } };
export type SliderItem = {
  cover?: { data: { attributes: BaseImageFragment } };
  fallback_color?: FALLBACK_COLOR;
  id: string;
  blog_article?: {
    data: {
      attributes: {
        category: { data: { attributes: { name: string } } };
        title: string;
        slug: string;
      };
    };
  };
  reference?: {
    data: {
      attributes: {
        category: { data: { attributes: { name: string } } };
        title: string;
        slug: string;
      };
    };
  };
  page?: {
    data: {
      attributes: {
        category: { data: { attributes: { name: string } } };
        Titel: string;
        Slug: string;
      };
    };
  };

  cta_text?: string;
};

const props = defineProps<{
  items: SliderItems;
  page?: SliderPage;
}>();
const sliderItems = computed(() => props.items);

// linkable parent page for blog posts
const page = ref(props.page);
const parentLink = computed(() => {
  return page?.value?.data?.attributes?.Slug
    ? `/${page?.value?.data?.attributes?.Slug}/`
    : "";
});

function buttonType(item: SliderItem | null) {
  if (item?.cover?.data?.attributes) return "primary";
  switch (item?.fallback_color) {
    case FALLBACK_COLOR.Black:
      return "primary";
    case FALLBACK_COLOR.White:
      return "secondary";
    case FALLBACK_COLOR.Green:
      return "secondary";
    case FALLBACK_COLOR.Gray:
      return "secondary";
    default:
      if (!item?.cover?.data?.attributes) return "secondary";
      return "primary";
  }
}

// swiper
</script>
<i18n lang="json">
{
  "de": {
    "cta": "Mehr erfahren"
  },
  "en": {
    "cta": "Learn more"
  }
}
</i18n>
<template>
  <section class="slider relative">
    <swiper :css-mode="true">
      <div
        v-if="sliderItems && sliderItems?.length > 1"
        class="container absolute left-1/2 top-0 z-10 -translate-x-1/2"
      >
        <module-slider-controls
          class="outer-space absolute right-0 top-0 px-5 py-10 pb-10 pt-5.5 lg:p-5.5"
          :items="sliderItems"
        />
      </div>
      <swiper-slide
        v-for="item in sliderItems"
        :key="item?.id"
        class="relative h-full"
      >
        <div v-if="item?.cover?.data?.attributes" class="image relative h-full">
          <base-image
            v-if="item?.cover?.data?.attributes"
            v-bind="item?.cover?.data?.attributes"
            :eager-loading="true"
            class="h-screen-lg w-full object-cover object-center lg:h-screen-xl"
          />
          <div class="overlay default-gradient absolute inset-0" />
        </div>
        <div
          v-else-if="item?.fallback_color"
          class="relative h-screen-xl"
          :class="{
            'bg-integer-black': item?.fallback_color === FALLBACK_COLOR.Black,
            'bg-integer-white': item?.fallback_color === FALLBACK_COLOR.White,
            'bg-integer-light-green':
              item?.fallback_color === FALLBACK_COLOR.Green,
            'bg-light-gray': item?.fallback_color === FALLBACK_COLOR.Gray,
          }"
        />
        <div v-else class="relative h-screen-lg lg:h-screen-xl" />
        <div
          class="outer-space absolute inset-y-0 left-0 flex w-full items-center py-10 pb-10 pt-5.5 text-center md:text-left"
          :class="{
            'text-integer-white':
              !item?.fallback_color ||
              item?.cover?.data?.attributes ||
              item?.fallback_color === FALLBACK_COLOR.Black,
            'text-integer-black':
              item?.fallback_color === FALLBACK_COLOR.White ||
              item?.fallback_color === FALLBACK_COLOR.Gray ||
              item?.fallback_color === FALLBACK_COLOR.Green,
          }"
        >
          <div class="container w-full">
            <h3 v-if="item.subheadline" class="mb-2">
              {{ item.subheadline }}
            </h3>
            <h2
              class="headline hyphens-auto break-words text-h2 md:max-w-screen-md"
            >
              {{
                item?.blog_article?.data?.attributes?.title ||
                item?.reference?.data?.attributes?.title ||
                item?.page?.data?.attributes?.Titel
              }}
            </h2>
            <base-button
              :type="buttonType(item)"
              transparent
              class="mt-7 w-full justify-center xs:w-auto"
              :class="{
                'hover:border-integer-black':
                  item?.fallback_color === FALLBACK_COLOR.White ||
                  item?.fallback_color === FALLBACK_COLOR.Gray ||
                  item?.fallback_color === FALLBACK_COLOR.Green,
              }"
              :to="
                parentLink
                  ? parentLink +
                    (item?.blog_article?.data?.attributes?.slug ||
                      item?.reference?.data?.attributes?.slug)
                  : item?.page?.data?.attributes?.Slug || ''
              "
              >{{ item?.cta_text || t("cta") }}</base-button
            >
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>
